import { Link } from 'react-router-dom'
import styles from '@/styles/HomePage.module.css'

const HomePage = () => {
  return (
    <div className={styles.home}>
      <section className={styles.hero}>
        <div className="container">
          <h1 className={styles.title}>Developer Platform <br />for Xe Computer</h1>
          <p className={styles.subtitle}>
            The future of computing built with vibe coding.
            <br />
            Create, develop, and contribute to the Xe ecosystem.
          </p>
          <div className={styles.actions}>
            <button className="button">Install CLI</button>
            <Link to="/docs" className="button secondary">View Documentation</Link>
          </div>
          
          <div className={styles.terminal}>
            <div className={styles.terminalHeader}>
              <div className={styles.terminalControls}>
                <span className={styles.terminalControl}></span>
                <span className={styles.terminalControl}></span>
                <span className={styles.terminalControl}></span>
              </div>
              <div className={styles.terminalTitle}>terminal</div>
              <div className={styles.terminalControls}></div>
            </div>
            <div className={styles.terminalBody}>
              <code className={styles.command}>
                <span className={styles.prompt}>$</span> curl -fsSL https://xe.dev/install.sh | sh
              </code>
              <code className={styles.output}>
                Downloading Xe CLI...
                <br />
                Installing...
                <br />
                Done!
                <br />
                <br />
                Run 'xe --help' to get started
              </code>
            </div>
          </div>
        </div>
      </section>
      
      <section className={styles.features}>
        <div className="container">
          <h2 className={styles.sectionTitle}>Core Features</h2>
          
          <div className={styles.featureGrid}>
            <div className={styles.feature}>
              <h3 className={styles.featureTitle}>CLI Tools</h3>
              <p className={styles.featureDescription}>
                Powerful command-line tools to interact with the Xe ecosystem.
                Create, manage, and deploy your applications.
              </p>
            </div>
            
            <div className={styles.feature}>
              <h3 className={styles.featureTitle}>Developer API</h3>
              <p className={styles.featureDescription}>
                Comprehensive API to integrate with Xe services.
                Build and extend functionality across the platform.
              </p>
            </div>
            
            <div className={styles.feature}>
              <h3 className={styles.featureTitle}>Documentation</h3>
              <p className={styles.featureDescription}>
                Extensive documentation covering all aspects of the Xe ecosystem.
                From getting started to advanced usage.
              </p>
            </div>
            
            <div className={styles.feature}>
              <h3 className={styles.featureTitle}>GitHub Integration</h3>
              <p className={styles.featureDescription}>
                Seamless integration with GitHub for source control, 
                issue tracking, and collaboration.
              </p>
            </div>
          </div>
        </div>
      </section>
      
      <section className={styles.ecosystem}>
        <div className="container">
          <h2 className={styles.sectionTitle}>Xe Ecosystem</h2>
          <p className={styles.sectionDescription}>
            The Xe ecosystem consists of multiple interconnected projects, all built with vibe coding.
          </p>
          
          <div className={styles.ecosystemGrid}>
            <a href="https://xe.computer" target="_blank" rel="noopener noreferrer" className={styles.ecosystemCard}>
              <h3 className={styles.ecosystemTitle}>Xe Computer</h3>
              <p className={styles.ecosystemDescription}>
                The flagship product for end users. The future of computing.
              </p>
            </a>
            
            <Link to="/docs/system" className={styles.ecosystemCard}>
              <h3 className={styles.ecosystemTitle}>Xe System</h3>
              <p className={styles.ecosystemDescription}>
                Native desktop application that provides system integration.
              </p>
            </Link>
            
            <Link to="/docs/cloud" className={styles.ecosystemCard}>
              <h3 className={styles.ecosystemTitle}>Xe Cloud</h3>
              <p className={styles.ecosystemDescription}>
                Cloud services built on Cloudflare Workers and Durable Objects.
              </p>
            </Link>
            
            <Link to="/docs/agent" className={styles.ecosystemCard}>
              <h3 className={styles.ecosystemTitle}>Xe Agent</h3>
              <p className={styles.ecosystemDescription}>
                AI-powered agent that understands your needs and simplifies tasks.
              </p>
            </Link>
          </div>
        </div>
      </section>
      
      <section className={styles.cta}>
        <div className="container">
          <h2 className={styles.ctaTitle}>Join the Xe developer community</h2>
          <p className={styles.ctaDescription}>
            Contribute to the future of computing. Build applications that put user agency first.
          </p>
          <div className={styles.ctaActions}>
            <button className="button">Get Started</button>
            <a href="https://github.com/Agent54" target="_blank" rel="noopener noreferrer" className="button secondary">GitHub</a>
          </div>
        </div>
      </section>
    </div>
  )
}

export default HomePage