import styles from '@/styles/DocsPage.module.css'

const DocsPage = () => {
  return (
    <div className={styles.docs}>
      <div className="container">
        <div className={styles.grid}>
          <aside className={styles.sidebar}>
            <div className={styles.sidebarContent}>
              <h3 className={styles.sidebarTitle}>Documentation</h3>
              
              <nav className={styles.sidebarNav}>
                <div className={styles.sidebarSection}>
                  <h4 className={styles.sidebarSectionTitle}>Getting Started</h4>
                  <ul className={styles.sidebarList}>
                    <li className={styles.sidebarItem}>
                      <a href="#" className={`${styles.sidebarLink} ${styles.active}`}>Introduction</a>
                    </li>
                    <li className={styles.sidebarItem}>
                      <a href="#" className={styles.sidebarLink}>Installation</a>
                    </li>
                    <li className={styles.sidebarItem}>
                      <a href="#" className={styles.sidebarLink}>Quick Start</a>
                    </li>
                  </ul>
                </div>
                
                <div className={styles.sidebarSection}>
                  <h4 className={styles.sidebarSectionTitle}>Core Concepts</h4>
                  <ul className={styles.sidebarList}>
                    <li className={styles.sidebarItem}>
                      <a href="#" className={styles.sidebarLink}>Xe Architecture</a>
                    </li>
                    <li className={styles.sidebarItem}>
                      <a href="#" className={styles.sidebarLink}>User Agency</a>
                    </li>
                    <li className={styles.sidebarItem}>
                      <a href="#" className={styles.sidebarLink}>Vibe Coding</a>
                    </li>
                  </ul>
                </div>
                
                <div className={styles.sidebarSection}>
                  <h4 className={styles.sidebarSectionTitle}>CLI</h4>
                  <ul className={styles.sidebarList}>
                    <li className={styles.sidebarItem}>
                      <a href="#" className={styles.sidebarLink}>Commands</a>
                    </li>
                    <li className={styles.sidebarItem}>
                      <a href="#" className={styles.sidebarLink}>Configuration</a>
                    </li>
                    <li className={styles.sidebarItem}>
                      <a href="#" className={styles.sidebarLink}>Advanced Usage</a>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </aside>
          
          <main className={styles.content}>
            <h1 className={styles.title}>Introduction to Xe Dev</h1>
            
            <div className={styles.metadata}>
              <span>Last updated: August 3, 2025</span>
              <a href="#" className={styles.edit}>Edit this page</a>
            </div>
            
            <div className={styles.contentBody}>
              <p>
                Welcome to the Xe Dev documentation. This guide will help you understand the Xe ecosystem and 
                how to develop applications and integrations for it.
              </p>
              
              <h2>What is Xe?</h2>
              <p>
                Xe is a next-generation computing ecosystem designed to put user agency first. Built with vibe coding,
                it combines AI-powered assistance with user control to create a more intuitive and powerful computing experience.
              </p>
              
              <h2>Developer Platform</h2>
              <p>
                The Xe Dev platform provides tools, APIs, and resources for developers to:
              </p>
              
              <ul>
                <li>Build applications that integrate with the Xe ecosystem</li>
                <li>Extend Xe's functionality with custom services</li>
                <li>Contribute to the core Xe projects</li>
                <li>Deploy and manage Xe services</li>
              </ul>
              
              <h2>Getting Started</h2>
              <p>
                The best way to get started with Xe development is to install the Xe CLI and explore the ecosystem:
              </p>
              
              <div className={styles.codeBlock}>
                <code>
                  curl -fsSL https://xe.dev/install.sh | sh
                </code>
              </div>
              
              <p>
                After installation, you can verify your setup with:
              </p>
              
              <div className={styles.codeBlock}>
                <code>
                  xe --version
                </code>
              </div>
              
              <h2>Core Components</h2>
              <p>
                The Xe ecosystem consists of several core components:
              </p>
              
              <div className={styles.componentGrid}>
                <div className={styles.component}>
                  <h3>Xe System</h3>
                  <p>Native application for desktop platforms</p>
                </div>
                
                <div className={styles.component}>
                  <h3>Xe Agent</h3>
                  <p>AI-powered agent for user assistance</p>
                </div>
                
                <div className={styles.component}>
                  <h3>Xe Cloud</h3>
                  <p>Cloud services and infrastructure</p>
                </div>
                
                <div className={styles.component}>
                  <h3>Xe Core</h3>
                  <p>Shared libraries and utilities</p>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  )
}

export default DocsPage