import { Link } from 'react-router-dom'
import styles from '@/styles/Footer.module.css'

const Footer = () => {
  const currentYear = new Date().getFullYear()
  
  return (
    <footer className={styles.footer}>
      <div className={`container ${styles.container}`}>
        <div className={styles.grid}>
          <div className={styles.branding}>
            <div className={styles.logo}>
              <span className={styles.logoText}>Xe</span>
              <span className={styles.logoHighlight}>.dev</span>
            </div>
            <p className={styles.description}>
              Developer platform for the Xe Computer ecosystem.
              Built with vibe coding.
            </p>
          </div>
          
          <div className={styles.links}>
            <h3 className={styles.title}>Resources</h3>
            <ul className={styles.list}>
              <li><Link to="/docs">Documentation</Link></li>
              <li><a href="https://github.com/Agent54" target="_blank" rel="noopener noreferrer">GitHub</a></li>
              <li><Link to="/docs/api">API Reference</Link></li>
            </ul>
          </div>
          
          <div className={styles.links}>
            <h3 className={styles.title}>Ecosystem</h3>
            <ul className={styles.list}>
              <li><a href="https://xe.computer" target="_blank" rel="noopener noreferrer">xe.computer</a></li>
              <li><a href="https://agent54.org" target="_blank" rel="noopener noreferrer">Agent54</a></li>
              <li><a href="https://xe.social" target="_blank" rel="noopener noreferrer">xe.social</a></li>
            </ul>
          </div>
          
          <div className={styles.links}>
            <h3 className={styles.title}>Connect</h3>
            <ul className={styles.list}>
              <li><a href="https://github.com/Agent54" target="_blank" rel="noopener noreferrer">GitHub</a></li>
              <li><a href="https://twitter.com/Agent54HQ" target="_blank" rel="noopener noreferrer">Twitter</a></li>
              <li><a href="/contact">Contact</a></li>
            </ul>
          </div>
        </div>
        
        <div className={styles.bottom}>
          <div className={styles.copyright}>
            &copy; {currentYear} Agent54. All rights reserved.
          </div>
          <div className={styles.legal}>
            <Link to="/terms">Terms</Link>
            <Link to="/privacy">Privacy</Link>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer