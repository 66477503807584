import { Link } from 'react-router-dom'
import styles from '@/styles/NotFoundPage.module.css'

const NotFoundPage = () => {
  return (
    <div className={styles.notFound}>
      <div className="container">
        <h1 className={styles.title}>404</h1>
        <h2 className={styles.subtitle}>Page Not Found</h2>
        <p className={styles.description}>
          The page you are looking for doesn't exist or has been moved.
        </p>
        <div className={styles.actions}>
          <Link to="/" className="button">Go Home</Link>
          <Link to="/docs" className="button secondary">View Documentation</Link>
        </div>
      </div>
    </div>
  )
}

export default NotFoundPage