import { Link } from 'react-router-dom'
import styles from '@/styles/Header.module.css'

const Header = () => {
  return (
    <header className={styles.header}>
      <div className={`container ${styles.container}`}>
        <div className={styles.logo}>
          <Link to="/">
            <span className={styles.logoText}>Xe</span>
            <span className={styles.logoHighlight}>.dev</span>
          </Link>
        </div>
        
        <nav className={styles.nav}>
          <ul className={styles.navList}>
            <li className={styles.navItem}>
              <Link to="/" className={styles.navLink}>Home</Link>
            </li>
            <li className={styles.navItem}>
              <Link to="/docs" className={styles.navLink}>Docs</Link>
            </li>
            <li className={styles.navItem}>
              <a href="https://github.com/Agent54" target="_blank" rel="noopener noreferrer" className={styles.navLink}>GitHub</a>
            </li>
          </ul>
        </nav>
        
        <div className={styles.actions}>
          <button className="button secondary">Install</button>
          <button className="button">Login with GitHub</button>
        </div>
      </div>
    </header>
  )
}

export default Header